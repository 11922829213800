<template>
  <span class="anchor"
        v-on:click="$handleScrollTo(params.selector, params.indent)">
    {{params.title}}
  </span>
</template>

<script>
export default {
  name: 'Anchor',
  props: {
    params: {},
  },
};
</script>
